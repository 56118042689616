<template>
  <div class="all-container">
    <div class="container-mb content-list">
      <div class="list-top">
        <div class="list-top-title">{{ $t('观光情报') }}</div>
      </div>
      <div v-if="dataList && dataList.length > 0" v-loading="dataListLoading" style="margin-top: 30px">
        <listPane ref="listPane" />
      </div>
      <div v-else v-loading="dataListLoading">
        <div class="list-not">
          {{ $t('列表暂无数据') }}
        </div>
      </div>
      <div class="list-page">
        <el-pagination :current-page="searchForm.page" :page-size="searchForm.pageSize" :total="sizeAll" background layout="prev, pager, next" @current-change="currentChangeHandle" />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import { departmentList, getAreaList } from '@/api/store'
import { formatMoney } from '@/utils/money'
import { weeks, storeService, storeShopType, minPriceList, maxPriceList } from '@/utils/public_data'
import { treeDataTranslate } from '@/utils/util'
import { mapState } from 'vuex'
import listPane from '@/views_mb/list/index'

export default {
  components: { listPane },
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0, 5)
    },
  },
  data() {
    return {
      searchForm: {
        keywords: '',
        page: 1,
        pageSize: 6
      },
      sizeAll: 0,
      dataList: [],
      service: storeService(),
      weeks: weeks(),
      areaList: [],
      moneyList: {},
      shopLogo: require('@/assets/images/shop.png'),
      forward: require('@/assets/images/forward.png'),
      notImg: require('@/assets/images/not-image.png'),
      shopType: storeShopType(),
      minPriceList: minPriceList(),
      maxPriceList: maxPriceList(),
      thumbsSwiper: {},
      swiper: {},
      dataForm: {},
      dinningTypes: [
        { value: 2, label: this.$t('午餐') },
        { value: 3, label: this.$t('晚餐') },
      ],
      dataListLoading: false,
      showFlag: false,
      detailForm: false,
      banner1: {
        en: require('@/assets/images/special-1.jpg'),
        zh: require('@/assets/images/special-2.png'),
        ko: require('@/assets/images/special-4.jpg'),
        ja: require('@/assets/images/special-3.jpg'),
      },
      banner2: require('@/assets/images/special-2.jpg'),
      banner3: require('@/assets/images/banner/banner-1.png'),
    }
  },
  computed: {
    lan() {
      return localStorage.getItem('locale')
    },
    ...mapState(['areaIds']),
  },
  watch: {
    lan(newVal) {
      if (newVal) {
        console.log(newVal)
        this.searchHandle()
      }
    },
  },
  created() {
    if (this.$route.query && this.$route.query.search) {
      this.searchForm = JSON.parse(this.$route.query.search)
    }
    this.getAreaLists()
    this.init()
  },
  mounted() {
    window.addEventListener('scroll', this.showTop)
  },
  methods: {
    handle(id) {
      this.$router.push({ path: '/detail', query: { id } })
    },
    // 地区选择触发
    handleAreaChange(val) {
      if (val && val.length > 0) {
        this.searchForm.areaId = val[val.length - 1]
      }
    },
    getAreaLists() {
      getAreaList({ ids: this.areaIds.join(',') }).then((response) => {
        if (response && response.data) {
          this.areaList = treeDataTranslate(response.data, 'areaId', 'parentId')
        }
      })
    },
    showTop() {
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 5) {
        this.showFlag = true
      } else {
        this.showFlag = false
      }
    },
    initSwiper(index) {
      this.thumbsSwiper[index] = new Swiper(`.img-thumbs${index}`, {
        spaceBetween: 10,
        slidesPerView: 3,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        loop: false,
        watchSlidesVisibility: true /*避免出现bug*/,
      })
      this.swiper[index] = new Swiper(`.img-top${index}`, {
        spaceBetween: 10,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        speed: 1000,
        loop: false,
        controller: {
          control: this.thumbsSwiper[index],
        },
        thumbs: {
          swiper: this.thumbsSwiper[index],
        },
      })
    },
    listHandle() {
      this.$router.push('/recommend')
    },
    listHandle2() {
      this.$router.push('/special')
    },
    listHandle3() {
      this.$router.push('/gototravel')
    },
    init() {
      if (this.lan === 'zh') {
        this.banner1 = require('@/assets/images/barbecue.jpg')
      }
      this.getDataList()
    },
    handlerBMap({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      // console.log('22222', BMap.Point(this.detailData.longitude, this.detailData.latitude))
      this.$set(this.center, 'lat', this.detailData.latitude)
      this.$set(this.center, 'lng', this.detailData.longitude)
      console.log(this.center)
    },
    searchHandle() {
      this.searchForm.page = 1
      this.getDataList()
    },
    getDataList() {
      // 获取数据
      this.dataListLoading = true
      departmentList({ ...this.searchForm, curLan: this.lan })
        .then((response) => {
          if (response && response.data) {
            this.dataList = response.data.list
            this.sizeAll = response.data.totalSize
            this.$nextTick(() => {
              if (this.dataList && this.dataList.length > 0) {
                this.$refs.listPane.init(this.dataList)
                this.dataListLoading = false
              }
            })
          } else {
            this.dataList = []
            this.sizeAll = 0
            this.dataListLoading = false
          }
        })
        .catch(() => {
          this.dataList = []
          this.sizeAll = 0
          this.dataListLoading = false
        })
    },
    // 当前页
    currentChangeHandle(val) {
      this.searchForm.page = val
      this.getDataList()
    },
    // 进入店铺
    detailHandle(id) {
      this.$router.push({ path: '/detail', query: { id: id } })
    },
    nearHandle() {
      this.$router.push('/nearby')
    },
  },
}
</script>
<style lang='scss' scoped>
.not-image {
  width: 120px;
  width: 120px;
  background: rgb(244, 244, 244);
}
.detail-form {
  padding: 10px;
  box-sizing: border-box;
  ::v-deep .el-form-item {
    width: 100%;
    margin-bottom: 0px;
  }
  ::v-deep .el-form-item__label {
    margin-bottom: 0px;
    padding: 0;
  }
}
.test {
  width: 100%;
  min-height: 900px;
}
.top-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  // background: url('../../assets/images/banner_hd.jpg') no-repeat;
  .banner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .banner-title {
      text-align: center;
      color: #fff;
      font-size: 30px;
    }
  }
}
.list-top {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .list-top-title {
    height: 20px;
    // line-height: 20px;
    padding-left: 20px;
    border-left: 4px solid #e7b530;
    font-size: 18px;
    font-weight: 600;
  }
  .list-top-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .list-key-inp {
      margin-left: 20px;
      width: 200px;
    }
    .list-key-btn {
      margin-left: -2px;
      background: #e7b530;
      color: #000;
      font-weight: 600;
      zoom: -10;
    }
  }
}
.special-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  height: auto;
  z-index: 1;
  border-radius: 3px;
  .content-box {
    padding: 10px;
    width: 80%;
    background: rgba(231, 181, 48, 0.7);
    border-radius: 2px;
    box-sizing: border-box;
    .content-inner-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border: 2px dashed #fff;
      border-radius: 6px;
      .special-title1 {
        margin-top: 10px;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
      .special-title2 {
        margin-top: 10px;
        font-size: 18px;
        color: #fff;
      }
      .enter-btn {
        padding: 7px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        &:hover {
          background: #fff;
          color: #e7b530;
          // border: 4px solid #E7B530;
        }
      }
    }
  }
}
.list {
  padding-top: 16px;
  border-top: 1px solid #dcdfe6;
  width: 100%;
  min-height: 140px;
  &:last-child {
    border-bottom: 1px solid #dcdfe6;
  }
  .list-dis-box {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #fdf6eb;
    .cost-discount {
      margin: 16px 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 12px;
      .cost-title {
        margin-top: 0px;
        font-size: 12px;
        .const-red-content {
          color: #f56c6c;
        }
      }
    }
  }
  .list-box {
    display: flex;
    flex-wrap: nowrap;
    .list-image-box {
      width: 120px;
      .list-image {
        width: 120px;
      }
    }
    .list-content {
      // padding: 0 6px;
      //
      flex: 1;
      // max-width: 240px;
      .list-cost {
        margin-top: 20px;
        display: flex;
        font-weight: 600;
        font-size: 12px;
        .cost-text {
          color: #f56c6c;
        }
      }
      .list-info-item {
        margin-top: 8px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 12px;
      }
      .list-rate {
        margin: 8px;
      }
      .item-title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .item-title-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          .list-title-content {
            width: 100%;
            margin-left: 0px;
            ::v-deep .el-link {
              display: block;
              width: 240px;
              font-size: 16px;
              font-weight: 600;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:hover {
                color: #e7b530;
              }
            }
            ::v-deep .el-link--inner {
              display: block;
              width: 100%;
              font-size: 16px;
              font-weight: 600;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:hover {
                color: #e7b530;
              }
            }
            margin-left: 10px;
          }
        }
        .shop-log {
          width: 28px;
        }
        .forward-logo {
          width: 28px;
        }
      }
    }
  }
}
.list-page {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  .el-pagination {
    ::v-deep .btn-prev {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .btn-next {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .el-pager {
      .number {
        background-color: #fff;
        color: #909399;
        border: 1px solid #dcdfe6;
      }
      li:not(.disabled).active {
        background-color: #e7b530;
      }
    }
  }
}
.list-not {
  width: 100%;
  min-height: 160px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-box {
  width: 100%;
  // height: 40px;
  // padding: 10px 10px;
  box-sizing: border-box;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  .search-warpper {
    display: flex;
    padding: 10px 10px;
    box-sizing: border-box;
    .search-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      .btn-icon {
        font-size: 20px;
      }
    }
  }
}
.search-box2 {
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  height: 30px;
  background: #fff;
  padding: 10px 0px;
  z-index: 1000;
  // box-sizing: border-box;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  .search-warpper {
    display: flex;
    width: 96%;
    padding: 0px 10px;
    // box-sizing: border-box;
  }
}
.cost-btn {
  background: #e7b530;
  color: #fff;
  margin: 0 4px;
}
.flex-box {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.width-50 {
  width: 46%;
}
.banner-shadow {
  border-radius: 5px;
  -moz-box-shadow: 0px 1px 5px #333333;
  -webkit-box-shadow: 0px 1px 5px #333333;
  box-shadow: 0px 1px 5px #333333;
}
.content-list {
  min-height: 76vh;
}
</style>
