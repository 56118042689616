<template>
  <div>
    <div class="container">
      <div v-if="showFlag" class="search-box2">
        <div class="search-warpper">
          <el-input v-model="searchForm.keywords" size="small" :placeholder="$t('附近餐厅名称')" />
          <el-button class="cost-btn" size="small" icon="el-icon-search" @click="searchHandle">{{ $t("搜索") }}</el-button>
        </div>
      </div>
    </div>
    <div class="container content-list">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="1">
          <span slot="label" class="tab-title">{{ $t("观光情报") }}</span>
        </el-tab-pane>
      </el-tabs>
      <div v-if="dataList && dataList.length > 0" v-loading="dataListLoading">
        <listPane ref="listPane" />
      </div>
      <div v-else>
        <div class="list-not" v-loading="dataListLoading">
          {{ $t("列表暂无数据") }}
        </div>
      </div>
      <div v-if="activeName === '1'" class="list-page">
        <el-pagination :current-page="searchForm.page" :page-size="searchForm.pageSize" :total="sizeAll" background layout="prev, pager, next" @current-change="currentChangeHandle" />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import { departmentList, getAreaList, storeRecommendList } from '@/api/store'
import { formatMoney } from '@/utils/money'
import { weeks, storeService, storeShopType, minPriceList, maxPriceList } from '@/utils/public_data'
import { treeDataTranslate } from '@/utils/util'
import listPane from '@/views_pc/list/index'
import { mapState } from 'vuex'
export default {
  components: { listPane },
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0, 5)
    },
  },
  data() {
    return {
      searchForm: {
        keywords: '',
        page: 1,
        pageSize: 10
      },
      sizeAll: 0,
      dataList: [],
      service: storeService(),
      weeks: weeks(),
      moneyList: {},
      shopLogo: require('@/assets/images/shop.png'),
      forward: require('@/assets/images/forward.png'),
      notImg: require('@/assets/images/not-image.png'),
      shopType: storeShopType(),
      thumbsSwiper: {},
      swiper: {},
      dataForm: {},
      dinningTypes: [
        { value: 2, label: this.$t('午餐') },
        { value: 3, label: this.$t('晚餐') },
      ],
      banner1: {
        en: require('@/assets/images/special-1.jpg'),
        zh: require('@/assets/images/special-2.png'),
        ko: require('@/assets/images/special-4.jpg'),
        ja: require('@/assets/images/special-3.jpg'),
      },
      banner2: require('@/assets/images/special-2.jpg'),
      videoList: [],
      videoLists: [
        {
          src: require('@/assets/images/video-04.jpg'),
          url: 'https://www.youtube.com/embed/zdIxgGyB3DM',
        },
        {
          src: require('@/assets/images/video-01.jpg'),
          url: 'https://www.youtube.com/embed/I9hcSbjx6aQ',
        },
        {
          src: require('@/assets/images/video-02.jpg'),
          url: 'https://www.youtube.com/embed/VwaDQVEFV34',
        },
        {
          src: require('@/assets/images/video-03.jpg'),
          url: 'https://www.youtube.com/embed/7i3VWDMCabc',
        },
      ],
      minPriceList: minPriceList(),
      maxPriceList: maxPriceList(),
      videoUrl: 'https://www.youtube.com/embed/zdIxgGyB3DM',
      areaList: [],
      videoActive: 0,
      videoIndex: 0,
      activeName: '1',
      showFlag: false,
      dataListLoading: false,
    }
  },
  computed: {
    lan() {
      return localStorage.getItem('locale')
    },
    ...mapState(['areaIds']),
  },
  watch: {
    // dataList: {
    //   handler(newVal) {
    //     if (newVal) {
    //       this.initSwiper()
    //     }
    //   },
    //   deep: true
    // },
    lan(newVal) {
      if (newVal) {
        console.log(newVal)
        this.searchHandle()
      }
    },
  },
  created() {
    if (this.$route.query && this.$route.query.search) {
      this.searchForm = JSON.parse(this.$route.query.search)
      console.log(this.searchForm)
    }
    this.getAreaLists()
    this.init()
    this.videoList = this.videoLists.slice(this.videoIndex, this.videoIndex + 5)
  },
  mounted() {
    window.addEventListener('scroll', this.showTop)
  },
  methods: {
    handleClick() {
      this.getDataList()
    },
    prevVideo() {
      if (this.videoIndex <= 0) {
        this.videoIndex = 0
        this.videoList = this.videoLists.slice(this.videoIndex, this.videoIndex + 5)
        this.$message.warning(this.$t('到底了'))
      } else {
        this.videoIndex = this.videoIndex - 5
        this.videoList = this.videoLists.slice(this.videoIndex, this.videoIndex + 5)
      }
      this.videoActive = 0
      this.videoUrl = this.videoList[0].url
    },
    nextVideo() {
      if (this.videoIndex + 5 >= this.videoLists.length - 1) {
        this.videoIndex = this.videoLists.length - 5
        this.videoList = this.videoLists.slice(this.videoIndex, this.videoIndex + 5)
        this.$message.warning(this.$t('到底了'))
      } else {
        this.videoIndex = this.videoIndex + 5
        this.videoList = this.videoLists.slice(this.videoIndex, this.videoIndex + 5)
      }
      this.videoActive = 0
      this.videoUrl = this.videoList[0].url
    },
    changeVideo(url, index) {
      this.videoUrl = url
      this.videoActive = index
    },
    nearHandle() {
      this.$router.push('/nearby')
    },
    showTop() {
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 150) {
        this.showFlag = true
      } else {
        this.showFlag = false
      }
    },
    // 地区选择触发
    handleAreaChange(val) {
      if (val && val.length > 0) {
        this.searchForm.areaId = val[val.length - 1]
      }
    },
    getAreaLists() {
      getAreaList({ ids: this.areaIds.join(',') }).then((response) => {
        if (response && response.data) {
          this.areaList = treeDataTranslate(response.data, 'areaId', 'parentId')
        }
      })
    },
    initSwiper(index) {
      this.thumbsSwiper[index] = new Swiper(`.img-thumbs${index}`, {
        spaceBetween: 10,
        slidesPerView: 3,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        loop: false,
        watchSlidesVisibility: true /*避免出现bug*/,
      })
      this.swiper[index] = new Swiper(`.img-top${index}`, {
        spaceBetween: 10,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        speed: 1000,
        loop: false,
        controller: {
          control: this.thumbsSwiper[index],
        },
        thumbs: {
          swiper: this.thumbsSwiper[index],
        },
      })
    },
    listHandle() {
      this.$router.push('/recommend')
    },
    listHandle2() {
      this.$router.push('/special')
    },
    init() {
      this.getDataList()
    },
    handlerBMap({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      // console.log('22222', BMap.Point(this.detailData.longitude, this.detailData.latitude))
      this.$set(this.center, 'lat', this.detailData.latitude)
      this.$set(this.center, 'lng', this.detailData.longitude)
      console.log(this.center)
    },
    searchHandle() {
      this.searchForm.page = 1
      this.getDataList()
    },
    getDataList() {
      // 获取数据
      this.dataListLoading = true
      departmentList({ ...this.searchForm, curLan: this.lan })
        .then((response) => {
          if (response && response.data) {
            this.dataList = response.data.list
            this.sizeAll = response.data.totalSize
            this.dataListLoading = false
            this.$nextTick(() => {
              if (this.dataList && this.dataList.length > 0) {
                this.$refs.listPane.init(this.dataList)
              }
            })
          } else {
            this.dataList = []
            this.sizeAll = 0
            this.dataListLoading = false
          }
        })
        .catch(() => {
          this.dataList = []
          this.sizeAll = 0
          this.dataListLoading = false
        })
    },
    getDataList2(recommendActivity) {
      // 获取数据
      this.dataListLoading = true
      storeRecommendList({ activity: recommendActivity })
        .then((response) => {
          if (response && response.data) {
            this.dataList = response.data
            this.$nextTick(() => {
              if (this.dataList && this.dataList.length > 0) {
                this.$refs.listPane.init(this.dataList)
              }
            })
            this.dataListLoading = false
          } else {
            this.dataList = []
            this.sizeAll = 0
            this.dataListLoading = false
          }
        })
        .catch(() => {
          this.dataList = []
          this.sizeAll = 0
          this.dataListLoading = false
        })
    },
    // 当前页
    currentChangeHandle(val) {
      this.searchForm.page = val
      this.getDataList()
    },
    // 进入店铺
    detailHandle(id) {
      this.$router.push({ path: '/detail', query: { id } })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__active-bar {
  color: #e7b530;
  background-color: #e7b530;
}
::v-deep .is-active {
  color: #e7b530;
  font-size: 6px;
}
::v-deep .el-tabs__item {
  text-align: center;
  padding: 0px 10px;
  margin: 0;
  height: 45px;
  &:hover {
    color: #e7b530;
  }
  // width: 50%;
}
::v-deep .is-active {
  color: #e7b530;
}
::v-deep .el-tabs__active-bar {
  text-align: center;
  // margin-top: 10px;
  margin: 0 auto;
  height: 4px;
  color: #e7b530;
  background-color: #e7b530;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #fff;
}
.tab-title {
  padding: 0px 20px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.list-top {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .list-top-title {
    height: 20px;
    // line-height: 20px;
    padding-left: 20px;
    border-left: 4px solid #e7b530;
    font-size: 24px;
    font-weight: 600;
  }
  .list-top-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .list-key-inp {
      margin-left: 20px;
      width: 200px;
    }
    .list-key-btn {
      margin-left: -2px;
      background: #e7b530;
      color: #000;
      font-weight: 600;
      zoom: -10;
    }
  }
}
.list-not {
  width: 100%;
  min-height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list {
  padding-top: 20px;
  border-top: 1px solid #dcdfe6;
  width: 100%;
  min-height: 260px;
  &:hover {
    transform: translateY(-1px);
    z-index: 20;
    background: rgba(240, 240, 240, 0.3);
  }
  .swiper-box {
    width: 180px;
    min-height: 250px;
    .list-left-image {
      width: 180px;
      height: 180px;
    }
    .list-image-thumbs {
      margin-top: 8px;
      width: 60px;
      height: 54px;
    }
  }
  .item-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .item-title-content {
      display: flex;
      align-items: center;
      .list-title-content {
        ::v-deep .el-link {
          font-size: 24px;
          font-weight: 400;
          color: #000;
          &:hover {
            color: #e7b530;
          }
        }
        margin-left: 10px;
      }
    }
    .shop-log {
      width: 28px;
    }
    .forward-logo {
      width: 28px;
    }
  }
  .list-rate {
    margin: 8px 0 10px;
    ::v-deep .el-rate__icon {
      font-size: 24px;
    }
    ::v-deep .el-rate__text {
      font-size: 18px;
    }
  }
  .list-service {
    margin-top: 12px;
    display: flex;
    .service-item {
      display: flex;
      .service-logo {
        width: 16px;
        margin-right: 10px;
      }
      .service-content {
        font-size: 12px;
      }
    }
  }
  .list-cost {
    margin-top: 20px;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    .cost-text {
      color: #f56c6c;
    }
  }
  .list-c2 {
    display: flex;
    justify-content: space-between;
    .cost-btn {
      background: #e7b530;
      color: #000;
      font-size: 14px;
    }
  }
  .cost-discount {
    margin: 16px 0;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    .cost-title {
      margin-top: 0px;
      margin-right: 20px;
      font-size: 14px;
      .const-red-content {
        color: #f56c6c;
      }
    }
  }
  .list-info {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    .list-info-item {
      margin-right: 20px;
    }
  }
  .list-detail {
    margin-top: 12px;
    display: flex;
    .detail-content {
      // margin-top: 10px;
      // width: 700px;
      flex: 1;
      //height: 20px;
      line-height: 20px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .detail-btn {
      width: 100px;
    }
  }
}
.list-page {
  margin-bottom: 20px;
  text-align: center;
  .el-pagination {
    ::v-deep .btn-prev {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .btn-next {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .el-pager {
      .number {
        background-color: #fff;
        color: #909399;
        border: 1px solid #dcdfe6;
      }
      li:not(.disabled).active {
        background-color: #e7b530;
      }
    }
  }
}
.special-box {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
  height: 280px;
  z-index: 1;
  border-radius: 3px;
  .content-box {
    padding: 10px;
    width: 46%;
    background: rgba(231, 181, 48, 0.7);
    border-radius: 2px;
    box-sizing: border-box;
    .content-inner-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border: 2px dashed #fff;
      border-radius: 6px;
      .special-title1 {
        margin-top: 10px;
        text-align: center;
        font-size: 60px;
        font-weight: 600;
        color: #fff;
      }
      .special-title2 {
        margin-top: 10px;
        font-size: 30px;
        color: #fff;
        font-weight: 600;
        line-height: 38px;
        white-space: pre-wrap;
      }
      .enter-btn {
        padding: 14px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        &:hover {
          background: #fff;
          color: #e7b530;
          // border: 4px solid #E7B530;
        }
      }
    }
  }
}
.not-image {
  width: 180px;
  height: 180px;
  background: rgb(244, 244, 244);
}
.not-thumbs-box {
  margin-top: 8px;
  display: flex;
  .not-image2 {
    margin-right: 10px;
    background: rgb(244, 244, 244);
    width: 54px;
    height: 54px;
  }
}
.mr-20 {
  margin-right: 20px;
}
.top-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 180px;
  // background: rgb(94, 94, 94);
  // background: url('../../assets/images/banner_hd_02.png');

  .banner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // background: rgba(0,0,0,0.4);
    z-index: 100;
    .banner-title {
      text-align: center;
      color: #fff;
      font-size: 46px;
    }
    .banner-warper {
      height: 100px;
      width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 10px;
      background: #fff;
      overflow: hidden;
      -moz-box-shadow: 2px 2px 10px rgba(248, 182, 42, 0.5);
      -webkit-box-shadow: 2px 2px 10px rgba(248, 182, 42, 0.5);
      box-shadow: 2px 2px 10px rgba(248, 182, 42, 0.5);
      .cost-btn {
        background: #e7b530;
        color: #fff;
        font-size: 14px;
        width: 120px;
        height: 40px;
      }
      .banner-btn {
        height: 40px;
        padding: 4px 8px;
      }
      .banner-form {
        // margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        width: 100%;
        height: 140px;
        // line-height: 100px;
        margin-bottom: 10px;
        // line-height: 140px;
        z-index: 200;
        .short-inp {
          width: 120px;
          margin-right: 10px;
        }
        .mid-inp {
          width: 120px;
        }
        .flex-box {
          margin-top: 10px;
          display: flex;
        }
        ::v-deep .el-form {
          padding: 0 10px;
        }
        .el-form-item {
          margin-bottom: 0;
        }
        ::v-deep .el-form-item__label {
          padding: 0;
        }
      }
    }
  }
  .coverage {
    background: #fff;
    color: #4f2f24;
    padding: 30px;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    .banner-title1 {
      font-size: 42px;
      font-weight: 600;
      padding: 60px 180px;
      border: 2px solid #4f2f24;
    }
    .banner-title1 span {
      font-size: 64px;
      font-weight: 800;
      margin-left: 16px;
    }
  }
}
.form-label {
  font-size: 16px;
  font-weight: 600;
  ::v-deep .el-form-item__label {
    font-size: 16px;
    color: #333;
  }
}
.search-box2 {
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  margin: 0 auto;
  height: 30px;
  background: #fff;
  padding: 10px 0;
  z-index: 1000;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  .search-warpper {
    width: 1080px;
    margin: 0 auto;
    display: flex;
  }
  .cost-btn {
    background: #e7b530;
    color: #fff;
    font-size: 14px;
  }
}
.ml-10 {
  margin-left: 10px;
}
.banner-shadow {
  border-radius: 5px;
  -moz-box-shadow: 0px 1px 5px #333333;
  -webkit-box-shadow: 0px 1px 5px #333333;
  box-shadow: 0px 1px 5px #333333;
}
.flex-box2 {
  margin-top: 40px;
}
.media-box {
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
  .media-left-box {
    margin-right: 20px;
    width: 720px;
    height: auto;
    .video-list {
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      position: relative;
      .video-item {
        width: 128px;
        height: 72px;
        margin-left: 20px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        .video-img {
          width: 128px;
          height: 72px;
          padding: 0px;
        }
      }
      .first-item {
        margin-left: 0;
      }
      .video-list-left {
        position: absolute;
        top: 30%;
        left: 0;
        width: 18px;
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: rgba(255, 255, 255, 0.5);
        z-index: 100;
        font-size: 16px;
        color: #fff;
      }
      .video-list-right {
        position: absolute;
        top: 30%;
        right: 0;
        width: 18px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.5);
        z-index: 100;
        font-size: 16px;
        color: #fff;
      }
    }
  }
  .media-right-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    .media-link-box {
      margin-bottom: 20px;
    }
    .meida-banner-img {
      width: 100%;
      height: 238px;
      &:hover {
        -moz-box-shadow: 2px 2px 5px #333333;
        -webkit-box-shadow: 2px 2px 5px #333333;
        box-shadow: 2px 2px 5px #333333;
        transition: 500ms;
        border-radius: 20px;
      }
    }
  }
}
.video-active {
  // border: 3px solid #f8b62b;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 122px;
    height: 66px;
    border: 3px solid #f8b62b;
    z-index: 100;
    background: transparent;
  }
}
.content-list {
  margin-top: 60px;
  min-height: 79vh;
}
</style>
